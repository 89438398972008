<template>
  <div style="text-align: left;margin-left: 0px;">
    <div v-for="(item,index) in myvalue" v-bind:key="index">
      <van-row style="background-color: rgb(212 229 255);height: 50px;line-height: 50px;padding-left: 20px;">
        <van-col span="16"> {{item.cname}}
        </van-col>
        <van-col span="8" style="text-align: -webkit-center;">
          <van-uploader :after-read="afterRead" :name="item.cname" accept="image/png, image/jpeg,image/jpg">
            <van-button icon="plus" type="primary" size="mini">上传文件</van-button>
          </van-uploader>
        </van-col>
      </van-row>

      <div class="van-cell">
        <m_list_pic_list_type_sub :myvalue="item.list" @on_myupdate="on_myupdate" />
      </div>
      <!-- <div class="van-cell">
        {{item.cnote}}
      </div> -->
    </div>

    <!-- <van-cell-group  >
      <van-cell v-for="(item,index) in myvalue" v-bind:key="index">

        <template #title>
          <span class="custom-title">{{item.cname}}</span>
        </template>
        <template #value>

        </template>
        <template #label>
          <m_list_pic_list_type_sub :myvalue="item.list" @on_myupdate="on_myupdate" />
          {{item.cnote}}
        </template>
      </van-cell> 
    </van-cell-group> -->

  </div>
</template>
<script>
import { Notify } from 'vant';
import { jn_scuser_com } from "@/api/jn_scuser_com.js";
import m_list_pic_list_type_sub from './m_list_pic_list_type_sub';
export default {
  components: {
    m_list_pic_list_type_sub,
  },
  props: {
    myvalue: {
      default: []
    },
  },
  data() {
    return {
      mydata: [],
      sdata: { page: 1 },
    };
  },
  created() {
    this.load_api();
  },
  methods: {
    load_api() { },
    on_myupdate(data) {
      this.$emit('on_myupdate', data);
    },
    afterRead(file, name) {
      // 此时可以自行将文件上传至服务器
      // console.log(file);
      // console.log(name);
      // console.log(file.content);
      var dd = { jsj_type: name.name, tu: file.content };
      jn_scuser_com.com_info_xkz_upload_app(dd).then(response => {
        if (response.data.statusCode === "200") {
          Notify({ type: 'success', message: response.data.message });
          this.$emit('on_myupdate', true);
        } else {
          Notify({ type: 'warning', message: response.data.message });
        }
      });
    },
  },
};
</script>
<style scoped>
</style>