// menu 模块接口列表示例
import {
    resquest
} from './base'
import axios from '../http' // 导入http中创建的axios实例 
import Qs from 'qs'

export const jn_scuser_com = {

    //登录企业查看回复通知相关 - 通知列表
    api_user_scuser_com_article_article_list(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_article/article_list`, data2);
    },
    //登录企业查看回复通知相关 - 通知详情
    api_user_scuser_com_article_article_info(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_article/article_info`, data2);
    },
    //登录企业摄像头情况 - 登录企业摄像头列表
    api_user_scuser_com_cam_cam_list(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_cam/cam_list`, data2);
    },
    //登录企业摄像头情况 - 
    api_user_scuser_com_cam_cam_count(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_cam/cam_count`, data2);
    },
    //手机端 - 个人中心（登录企业人员） - 获取的我的登录信息
    api_user_scuser_com_info_getmyuser(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_info/getmyuser`, data2);
    },
    //手机端 - 个人中心（登录企业人员） - 获取个人信息
    api_user_scuser_com_info_getuserinfo(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_info/getuserinfo`, data2);
    },
    //手机端 - 个人中心（登录企业人员） - 修改密码
    api_user_scuser_com_info_passwd_edit(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_info/passwd_edit`, data2);
    },
    com_info_list_xkz_type(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_xkz/com_info_list_xkz_type`, data2);
    },
    com_info_list_xkz_all(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_xkz/com_info_list_xkz_all`, data2);
    },
    com_info_xkz_upload(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_xkz/com_info_xkz_upload`, data2);
    },
    com_info_xkz_upload_app(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_xkz/com_info_xkz_upload_app`, data2);
    },
    com_info_xkz_del_do(guid) {
        return axios.post(`${resquest}/api/user/scuser_com_xkz/com_info_xkz_del_do?guid=${guid}`);
    },
    com_info_xkz_upload_path(jsj_type) {
        return `${resquest}/api/user/scuser_com_xkz/com_info_xkz_upload?jsj_type=${jsj_type}`;
    },
    passwd_edit(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_info/passwd_edit`, data2);
    },
    scuser_com_vote_getlist(data) {
        var data2 = Qs.stringify(data);
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_vote/getlist`, data2);
    },
    scuser_com_vote_getinfo(guid) {
        //console.log("data2",data2);
        return axios.post(`${resquest}/api/user/scuser_com_vote/getinfo?guid=` + guid);
    },
    scuser_com_vote_tijiao_do(data) {
        //console.log("data2",data2);
        //var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_com_vote/tijiao_do`, data);
    },
    scuser_com_vote_check_istijiao(data) {
        //console.log("data2",data2);
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/user/scuser_com_vote/check_istijiao`, data2);
    },
    test_tu(data) {
        var data2 = Qs.stringify(data);
        return axios.post(`${resquest}/api/pub/scjg/test_tu`, data2);
    },
    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) {
                return pair[1];
            }
        }
        return (false);
    }
}

