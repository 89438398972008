<template>
  <div class="hu_notice_box">
    <van-nav-bar title="证件上报" left-text="返回" left-arrow fixed placeholder @click-left="$router.push('personalInfo')">
    </van-nav-bar>
    <m_list_pic_list_type :myvalue="data" @on_myupdate="on_myupdate" />
    <div style="height:100px;"></div>
  </div>
</template>
<script>
import m_list_pic_list_type from './m_list_pic_list_type';
import { jn_scuser_com } from "@/api/jn_scuser_com.js";
export default {
  components: {
    m_list_pic_list_type,
  },
  data() {
    return {
      data: [],
      sdata: { page: 1 },
    };
  },
  created() {
    this.load_api();
  },
  methods: {
    load_api() {
      jn_scuser_com.com_info_list_xkz_all().then(response => {
        console.log("com_info_list_xkz_all=", response.data);
        this.data = response.data.mydata;
      });
      //
    },
    on_myupdate(data) {
      if (data) {
        this.load_api();
      }
    }
  },
};
</script>
<style scoped>
</style>