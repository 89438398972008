<template>
  <div style="    width: inherit;">
    <div v-if="myvalue.length>0">
      <div v-for="(item,index) in myvalue" v-bind:key="index">
        <img :src="item.recpic" class="zkx_pic" />

        <van-row style="margin-bottom: 5px;">
          <van-col span="12" style="text-align: -webkit-center;">
            <span class="mytt_ok" v-if="item.isok==1">
              已上线
            </span>
            <span class="mytt" v-if="item.isok==0">
              待审核
            </span>
            <span class="mytt_no" v-if="item.isok==-1">
              不通过
            </span>
          </van-col>
          <van-col span="12" style="text-align: -webkit-center;">
            <van-button type="primary" size="mini" @click="showConfirmDel(item)">删除</van-button>
          </van-col>
        </van-row>

      </div>
    </div>
    <div v-if="myvalue.length==0" style="text-align:center">
      <van-empty image-size="20" description="暂无信息" />
    </div>
  </div>
</template>
<script>
import { Dialog, Notify } from 'vant';
import { jn_scuser_com } from "@/api/jn_scuser_com.js";
export default {
  props: {
    myvalue: {
      default: []
    },
  },
  data() {
    return {
      mydata: [],
      sdata: { page: 1 },
    };
  },
  created() {
    this.load_api();
  },
  methods: {
    load_api() { },
    showConfirmDel(data) {
      Dialog.confirm({
        title: '确认',
        message:
          '确定要删除这个图片吗 ?',
      })
        .then(() => {
          // on confirm
          jn_scuser_com.com_info_xkz_del_do(data.pkguid).then(response => {
            if (response.data.statusCode === "200") {
              Notify({ type: 'success', message: response.data.message });
              this.$emit('on_myupdate', true);
              return true;
            }
          });
        })
        .catch(() => {
          // on cancel
          this.$emit('on_myupdate', false);
        });

    },
  },
};
</script>
<style >
.noshuju {
  line-height: 49px;
  padding-left: 100px;
  color: gainsboro;
}
.zkx_pic {
  max-width: 90%;
}
.mytt {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.mytt {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.mytt {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.mytt_ok {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.mytt_no {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
</style>